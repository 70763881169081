import { Component, OnInit } from '@angular/core';
import { FramePdfService } from 'src/app/services/frame-pdf.service';
import { FrameCell } from 'src/app/models/frameCell';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Boletim } from 'src/app/models/boletim';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { NotaService } from 'src/app/services/nota.service';
import { Contadores } from 'src/app/models/contadoresLinha';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private readonly endpoint = environment.apiUrl + '/api/boletins';
  private query: any = {};
  private notas = [];
  
  constructor(private http: HttpClient, private frame: FramePdfService, private notaService: NotaService) {
  }

  getBoletins(filter): Observable<Boletim[]> {
    return this.http.get<Boletim[]>(this.endpoint + '?' + this.toQueryString(filter));
  }

  toQueryString(obj) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }


  ngOnInit() {
  }


  addFrames(tipo: number) {
    var filter: any = {};

    filter.idAluno = 1;
    filter.idPeriodo = 1;
    filter.idTurma = 1;

    this.getBoletins(filter).subscribe(
      result => {
        let boletins = result;        

        this.setQuery(boletins[0]);
        if (this.query.alunoId == 0) //Se o filtro não retornar nada. Tratar depois.
          return;        

        var counter = new Contadores();        
        counter.clear();
        this.frame.clear();       
        this.frame.AlunoBoletim(boletins[0]);

        this.notaService.getNotas(this.query).subscribe(
          result => {
            this.notas = result;            

            counter.disciplina = this.notas[0].atividade.disciplina.nome;
            //this.frame.setDisciplina(counter.disciplina);
            counter.tipoIndice = 0;

            for (var nota of this.notas) {

              counter.lines += 1;

              if (counter.disciplina != nota.atividade.disciplina.nome) {
                counter.disciplina = nota.atividade.disciplina.nome;
                counter.clear();
              }

              if (nota.atividade.vma > 0)
                nota.paa = (nota.vaa / nota.atividade.vma * 100).toFixed(1);
              else
                nota.paa = "0";

              this.adicionaCell(nota.atividade.tipoAtividade.nome, nota.atividade.classificacao, nota.atividade.tipoAtividade.tipoIndice, nota.atividade.disciplina.nome, 4, counter.lines,5);
              this.adicionaCell(nota.atividade.vma.toString(), nota.atividade.classificacao, nota.atividade.tipoAtividade.tipoIndice, nota.atividade.disciplina.nome, 5, counter.lines,5);
              this.adicionaCell(nota.vaa.toString(), nota.atividade.classificacao, nota.atividade.tipoAtividade.tipoIndice, nota.atividade.disciplina.nome, 6, counter.lines, 5);
              this.adicionaCell(nota.paa, nota.atividade.classificacao, nota.atividade.tipoAtividade.tipoIndice, nota.atividade.disciplina.nome, 7, counter.lines, 5);
              
              if (counter.txtTipoIndice == '') {
                counter.txtTipoIndice = nota.atividade.tipoAtividade.tipoIndice;
              }
              counter.somaPontos += nota.atividade.vma;
              counter.totVMA += nota.atividade.vma;
              counter.totVAA += nota.vaa;
              counter.tipoAtividade += 1;
              counter.tipoIndice += 1;
            }
            this.frame.imprimeBoletim(tipo);
          });
      });

  }
  setQuery(boletim) {
    this.query.idAluno = boletim.alunoId;
    this.query.idPeriodo = boletim.periodoId;
    this.query.idTurma = boletim.turmaId;
  }

  adicionaCell(text: string, classif: string, tipoIndice: string, disciplina: string, col: number, line: number, height: number) {
    var localCell = new FrameCell();
    localCell.text = text;
    localCell.x = col;
    localCell.y = line;
    localCell.h = height;
    localCell.classificacao = classif;
    localCell.tipoIndice = tipoIndice;
    localCell.disciplina = disciplina;
    this.frame.addCell(localCell);
  }
}

