<form #f="ngForm" (ngSubmit)="submit()" novalidate>
  <mat-card class="example-card mat-elevation-z4 card-container">
    <mat-card-title>
      <h4>
        <mat-icon>school</mat-icon> Alunos
      </h4>
    </mat-card-title>
    <hr />
    <mat-card-content>
      <div row>
        <mat-form-field class="col-md-2">
          <input [(ngModel)]="aluno.matricula" name="matricula" matInput placeholder="Matrícula">
        </mat-form-field>
        <mat-form-field class="col-md-7">
          <input [(ngModel)]="aluno.nome" name="nome" matInput placeholder="Nome do Aluno">
        </mat-form-field>
        <mat-form-field class="col-md-3">
          <input [(ngModel)]="aluno.dataNascimento" mask="00/00/0000" name="dataNascimento" matInput
            placeholder="Data de Nascimento">
        </mat-form-field>
      </div>
      <hr />
      <button mat-raised-button color = "primary" type="submit" value="Submit"><mat-icon>check</mat-icon>Salvar</button>
      <button mat-raised-button color = "warn" type="button" (click)="back()"><mat-icon>keyboard_backspace</mat-icon>Voltar</button>
      <button mat-raised-button *ngIf="aluno.id" color = "accent" type="button"(click)="delete()"><mat-icon>delete</mat-icon>Excluir</button>
    </mat-card-content>
  </mat-card>
</form>

