<div class="logo">
  <img width="140" src="assets/images/LudensLogo-2.png">
</div>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <!-- <a *ngIf="isLoggedIn" mat-list-item routerLink="/"(click)="drawer.close()">Dashboard</a> -->
      <a *ngIf="currentRole === 'Admin'" mat-list-item routerLink="/alunos"(click)="drawer.close()">Alunos</a>
      <a *ngIf="currentRole === 'Admin' || currentRole === 'User'" mat-list-item routerLink="/boletins"(click)="drawer.close()">Lançar Notas</a>
      <a *ngIf="currentRole === 'Admin' || currentRole === 'User'" mat-list-item routerLink="/imprimirBoletins"(click)="drawer.close()">Imprimir Boletins</a>
      <!-- <a *ngIf="currentRole === 'Admin'" mat-list-item routerLink="/professores"(click)="drawer.close()">Professores</a> -->
      <!-- <a *ngIf="currentRole === 'Admin'" mat-list-item routerLink="/turmas"(click)="drawer.close()">Turmas</a> -->
      <!-- <a *ngIf="currentRole === 'Admin'" mat-list-item routerLink="/periodos"(click)="drawer.close()">Períodos</a> -->
      <a *ngIf="currentRole === 'Admin'" mat-list-item routerLink="/atividades"(click)="drawer.close()">Atividades</a>
      <!-- <a *ngIf="currentRole === 'Admin'" mat-list-item routerLink="/tipoAtividades"(click)="drawer.close()">Tipos de Atividade</a>       -->
      <a  *ngIf="!isLoggedIn" mat-list-item routerLink="/login"(click)="drawer.close()">Login</a>
      <a  *ngIf="isLoggedIn" mat-list-item routerLink="/login"(click)="drawer.close()" (click)="Logout()">Sair</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z4">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span *ngIf="isLoggedIn">  Usuário: {{currentUser}}</span>

      <ng-container *ngIf="!(isHandset$ | async)">
          <!-- <a *ngIf="isLoggedIn" routerLink="/">Dashboard</a> -->
          <!-- <div class="logo">
              <img width="150" src="assets/images/LudensLogo.gif">
            </div> -->
            <span class="spacer"></span>
          <a *ngIf="currentRole === 'Admin'" routerLink="/alunos">Alunos</a>
          <a *ngIf="currentRole === 'Admin' || currentRole === 'User'" routerLink="/boletins">Lançar Notas</a>
          <a *ngIf="currentRole === 'Admin' || currentRole === 'User'" routerLink="/imprimirBoletins">Imprimir Boletins</a>
          <!-- <a *ngIf="currentRole === 'Admin'" routerLink="/professores">Professores</a> -->
          <!-- <a *ngIf="currentRole === 'Admin'" routerLink="/turmas">Turmas</a> -->
          <!-- <a *ngIf="currentRole === 'Admin'" routerLink="/periodos">Períodos</a> -->
          <a *ngIf="currentRole === 'Admin'" routerLink="/atividades">Atividades</a>
          <!-- <a *ngIf="currentRole === 'Admin'" routerLink="/tipoAtividades">Tipos de Atividade</a> -->
          <a *ngIf="!isLoggedIn" routerLink="/login">Login</a>
          <a *ngIf="isLoggedIn" routerLink="/login"(click)="Logout()">Sair</a>
          </ng-container>
    </mat-toolbar>

    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
