import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Periodo } from '../models/periodo';

@Injectable()
export class PeriodoService {
    public periodos = [];
    private readonly endpoint = environment.apiUrl + '/api/periodos';

    constructor(private http: HttpClient) {

    }


    getPeriodos(filter): Observable<Periodo[]> {  
      return this.http.get<Periodo[]>(this.endpoint);
    } 

      getPeriodo(id: String): Observable<Periodo> {
        return this.http.get<Periodo>(this.endpoint + '/' + id); 
      } 

      updatePeriodo(periodo: Periodo) {
        return this.http.put(this.endpoint + '/' + periodo.id, periodo);
        // return this.http.put(this.endpoint + '/' + periodo.id, periodo)
        // .map((data: any[]) => {
        //     this.periodos = data;
        //     return true;
        // })
      }

      createPeriodo(periodo) {
        return this.http.post(this.endpoint, periodo);
        // return this.http.post(this.endpoint, periodo)
        // .map((data: any[]) => {
        //     this.periodos = data;
        //     return true;
        // })
      }
      deletePeriodo(id) {
        return this.http.delete(this.endpoint + '/' + id);
        // return this.http.delete(this.endpoint + '/' + id)
        // .map((data: any[]) => {
        //     this.periodos = data;
        //     return true;
        // })
      }
}