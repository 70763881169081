import { Injectable } from '@angular/core';
import { FrameCell } from '../models/frameCell';
import * as jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class FramePdfService {
  private cells = [];
  private arrayClassif = [];
  private arrayPontos = [];
  private tipoIndice = [];
  private disciplinas = [];
  private totaisPeriodo = [];

  private baseAtividade: number;
  private topColAtividade: number;
  private lineHeight: number;
  private disciplina: string;
  private resultado: string;
  private boletim: any;
  private pagina: number;
  private textLineWidth = 90; // Máxima largura de linha antes de quebra em duas linhas.
  private xLinhaSimples = 4;
  private xLinhaDupla = 7;
  private xLinhaTripla = 10;

  private xPaa = 181; // Posição da última coluna
  private xVaa = 172; // Posição da penutima coluna
  private xVma = 163; // Posição da antepenutima coluna
  private xCC = 56; // Posição da coluna Componente curricular
  private xPontos = 26; // Posição da coluna de pontos
  private wPontos = 14; // Largura da caixa de atividades
  private xClassif = 40; // Posição da coluna de classificação
  private wClassif = 16; // Largura da caixa de classificação
  private xIndice = 12; // Posição da coluna de índice
  private wIndice = 14; // Largura da caixa de índice

  constructor() {}

  clear() {
    while (this.cells.length > 0) this.cells.pop();
    while (this.disciplinas.length > 0) this.disciplinas.pop();
    while (this.arrayClassif.length > 0) this.arrayClassif.pop();
    while (this.arrayPontos.length > 0) this.arrayPontos.pop();
    while (this.tipoIndice.length > 0) this.tipoIndice.pop();

    this.baseAtividade = 94; // Início das disciplinas.
    this.topColAtividade = this.baseAtividade;
    this.lineHeight = this.xLinhaSimples;
    this.disciplina = '';
    this.pagina = 1;
  }

  AlunoBoletim(boletim) {
    this.boletim = boletim;
  }

  setTotPeriodo(totais) {
    this.totaisPeriodo = totais;
    this.resultado = '';
    // Se for 3º PERÍODO pode exibir resultado final;
    if (this.boletim.periodo.nome === '3º PERÍODO') {
      var resultado = this.totaisPeriodo.filter(function (d) {
        return d.resultado === 'Reprovado(a)';
      });

      // Se reprovar em uma, reprova em tudo.
      if (resultado.length > 0) {
        this.resultado = 'Resultado final: Reprovado(a)';
      } else {
        var resultado = this.totaisPeriodo.filter(function (d) {
          return d.resultado === 'Aprovado(a)';
        });

        // Se todas as materias já tiverem o resultado  aprovado, aprova o aluno.
        if (resultado.length >= 8) {
          this.resultado = 'Resultado final: Aprovado(a)';
        } else {
          this.resultado = '';
        }
      }
    }
  }

  addCell(cell: FrameCell) {
    if (this.disciplina != cell.disciplina) {
      this.disciplinas.push(cell.disciplina);
      if (this.disciplina != '') {
        this.topColAtividade += 20; // Insere espaço para o rodapé
      }
      this.disciplina = cell.disciplina;
      if (this.disciplinas.length == 4 && this.pagina == 1) {
        this.topColAtividade = 40;
        this.pagina = 2;
      }
      if (this.disciplinas.length == 8 && this.pagina == 2) {
        this.topColAtividade = 40;
        this.pagina = 3;
      }
    }

    if (cell.x == 4) {
      if (cell.text.length <= 78) {
        this.lineHeight = this.xLinhaSimples;
      } else {
        if (cell.text.length > 78 && cell.text.length <= 144) {
          this.lineHeight = this.xLinhaDupla; // Se o texto for muito longo, vai quebra a linha no split. Então tem que aumentar o tamanho da caixa.
        } else {
          this.lineHeight = this.xLinhaTripla; // Se o texto for muito longo, vai quebra a linha no split. Então tem que aumentar o tamanho da caixa.
        }
      }
    }

    cell = this.getColPos(cell);

    cell.h = this.lineHeight;
    // Ao chegar no fim da linha, incrementa a altura proporcional.
    if (cell.x == this.xPaa) {
      this.topColAtividade += this.lineHeight;
    }

    // Aumenta o tamanho nas 3 últimas linhas quando não tem classificação.
    // JBS - nclusão de recuperação
    if (
      cell.x == this.xCC &&
      (cell.classificacao === '' || cell.classificacao === 'REC')
    ) {
      cell.x = this.xClassif;
      cell.w += this.wClassif;
    }
    this.cells.push(cell);
  }

  getColPos(cell: FrameCell) {
    switch (cell.x) {
      case 4: {
        (cell.x = this.xCC), (cell.y = this.topColAtividade), (cell.w = 107);
        break;
      }
      case 5: {
        (cell.x = this.xVma), (cell.y = this.topColAtividade), (cell.w = 9);
        break;
      }
      case 6: {
        (cell.x = this.xVaa), (cell.y = this.topColAtividade), (cell.w = 9);
        break;
      }
      case 7: {
        (cell.x = this.xPaa), (cell.y = this.topColAtividade), (cell.w = 12);
        break;
      }
    }
    return cell;
  }

  removeCell() {
    return this.cells.pop();
  }

  getCellbyId(id: number) {
    return this.cells[id];
  }

  populaTipoIndice() {
    var frmTipoIndice: FrameCell;
    var ini: number;
    var fim: number;

    for (var disciplina of this.disciplinas) {
      var temp = this.cells.filter(function (d) {
        return d.disciplina === disciplina;
      });

      var tipoIndice = temp.filter(function (a) {
        return a.tipoIndice === 'C';
      });

      ini = tipoIndice[0].y;
      fim = tipoIndice[tipoIndice.length - 1].y;

      frmTipoIndice = new FrameCell();
      frmTipoIndice.x = this.xIndice;
      frmTipoIndice.y = ini;
      frmTipoIndice.w = this.wIndice;
      frmTipoIndice.h = fim - ini + tipoIndice[tipoIndice.length - 1].h;
      frmTipoIndice.disciplina = disciplina;
      frmTipoIndice.text = 'Índice Cognitivo';
      this.tipoIndice.push(frmTipoIndice);

      tipoIndice = temp.filter(function (a) {
        return a.tipoIndice === 'F';
      });

      ini += frmTipoIndice.h;
      try {
      fim =
        tipoIndice[tipoIndice.length - 1].y +
        tipoIndice[tipoIndice.length - 1].h;
      }
      catch{
        //console.log(temp);
      }
      frmTipoIndice = new FrameCell();
      frmTipoIndice.x = this.xIndice;
      frmTipoIndice.y = ini;
      frmTipoIndice.w = this.wIndice;
      frmTipoIndice.h = fim - ini;
      frmTipoIndice.disciplina = disciplina;
      frmTipoIndice.text = 'Índice Formativo';
      this.tipoIndice.push(frmTipoIndice);

      // JBS - inclusão de recuperação
      tipoIndice = temp.filter(function (a) {
        return a.tipoIndice === 'R';
      });

      if (tipoIndice.length > 0) {
        ini += frmTipoIndice.h;
        try {
        fim =
          tipoIndice[tipoIndice.length - 1].y +
          tipoIndice[tipoIndice.length - 1].h;
        }
        catch{
          //console.log(temp);
        }

        frmTipoIndice = new FrameCell();
        frmTipoIndice.x = this.xIndice;
        frmTipoIndice.y = ini;
        frmTipoIndice.w = this.wIndice;
        frmTipoIndice.h = fim - ini;
        frmTipoIndice.disciplina = disciplina;
        frmTipoIndice.text = 'REC';
        this.tipoIndice.push(frmTipoIndice);
      }
    }
  }

  populaClassificacao() {
    var frmClassificacao: FrameCell;
    var iniClassif = this.cells[0].y;
    var disciplina = this.cells[0].disciplina;
    var classificacao = this.cells[0].classificacao;

    var fimClassif = 0;

    for (var i = 0; i < this.cells.length; i++) {
      if (this.cells[i].x == this.xPaa) {
        if (disciplina == this.cells[i].disciplina) {
          if (classificacao == this.cells[i].classificacao) {
            fimClassif = this.cells[i].y;
          } else {
            frmClassificacao = new FrameCell();
            frmClassificacao.text = classificacao;
            frmClassificacao.x = 40;
            frmClassificacao.w = 16;
            frmClassificacao.y = iniClassif;
            frmClassificacao.h = fimClassif - iniClassif + this.cells[i - 4].h;
            frmClassificacao.disciplina = disciplina;
            this.arrayClassif.push(frmClassificacao); //Insere no vetor de classificações
            iniClassif = fimClassif + this.cells[i - 4].h; // Pega altura da última célula da linha anterior (i-4);
            fimClassif = iniClassif;
            classificacao = this.cells[i].classificacao;
          }
          fimClassif = this.cells[i].y;
        } else {
          disciplina = this.cells[i].disciplina;
          classificacao = this.cells[i].classificacao;
          iniClassif = this.cells[i].y;
          fimClassif = iniClassif;
        }
      }
    }
  }

  populaPontos() {
    var vma = this.xVma;
    var frmPontos: FrameCell;
    var tmpPontos = 0;

    for (var disciplina of this.disciplinas) {
      var temp = this.cells.filter(function (d) {
        return d.disciplina === disciplina;
      });

      if (temp.length > 0) {
        var ap = temp.filter(function (a) {
          //return (a.classificacao === 'Atividades Pontuadas')
          // JBS retirada da divisão de atividades pontuadas e provas
          return (
            a.classificacao === 'Atividades Pontuadas' ||
            a.classificacao === 'T1' ||
            a.classificacao === 'T2' ||
            a.classificacao === 'PR'
          );
        });
      }

      if (ap.length > 0) {
        var qtdPontos = ap
          .filter(function (a) {
            return a.x === vma;
          })
          .map((c) => Number(c.text))
          .reduce((sum, current) => sum + current);
      }

      var iniAP = ap[0].y;
      var fimAP = ap[ap.length - 1].y + ap[ap.length - 1].h;

      frmPontos = new FrameCell();
      frmPontos.text = qtdPontos.toFixed(0) + ' pontos';
      frmPontos.x = this.xPontos;
      frmPontos.w = this.wPontos;
      frmPontos.y = iniAP;
      frmPontos.h = fimAP - iniAP;
      frmPontos.disciplina = disciplina;
      this.arrayPontos.push(frmPontos); //Insere no vetor de classificações

      qtdPontos = 0;

      // if (temp.length > 0) {
      //   var outros = temp.filter(function (a) {
      //     return (a.classificacao === 'T1')
      //   });
      // }
      // if (outros.length > 0) {
      //   tmpPontos = outros.filter(function (a) {
      //     return (a.x === vma)
      //   }).map(c => Number(c.text))
      //     .reduce((sum, current) => sum + current);

      //   var iniOutros = outros[0].y;
      //   qtdPontos += tmpPontos;
      // }

      // if (temp.length > 0) {
      //   outros = temp.filter(function (a) {
      //     return (a.classificacao === 'T2')
      //   });
      // }
      // if (outros.length > 0) {
      //   tmpPontos = outros.filter(function (a) {
      //     return (a.x === vma)
      //   }).map(c => Number(c.text))
      //     .reduce((sum, current) => sum + current);
      //   qtdPontos += tmpPontos;
      // }

      // if (temp.length > 0) {
      //   outros = temp.filter(function (a) {
      //     return (a.classificacao === 'PR')
      //   });
      // }
      // if (outros.length > 0) {
      //   tmpPontos = outros.filter(function (a) {
      //     return (a.x === vma)
      //   }).map(c => Number(c.text))
      //     .reduce((sum, current) => sum + current);
      //   qtdPontos += tmpPontos;
      //   var fimOutros = outros[outros.length - 1].y + outros[outros.length - 1].h;
      // }

      // if (qtdPontos > 0) {
      //   frmPontos = new FrameCell();
      //   frmPontos.text = qtdPontos.toFixed(0) + ' pontos';
      //   frmPontos.x = this.xPontos;
      //   frmPontos.w = this.wPontos;
      //   frmPontos.y = iniOutros;
      //   frmPontos.h = fimOutros - iniOutros;
      //   frmPontos.disciplina = disciplina;
      //   this.arrayPontos.push(frmPontos); //Insere no vetor de classificações
      // }

      qtdPontos = 0;
      var vazios = temp.filter(function (a) {
        return a.classificacao === '';
      });

      if (vazios.length > 0) {
        tmpPontos = vazios
          .filter(function (a) {
            return a.x === vma;
          })
          .map((c) => Number(c.text))
          .reduce((sum, current) => sum + current);

        var iniVazios = vazios[0].y;
        var fimVazios =
          vazios[vazios.length - 1].y + vazios[vazios.length - 1].h;
        qtdPontos += tmpPontos;
      }

      if (qtdPontos > 0) {
        frmPontos = new FrameCell();
        frmPontos.text = qtdPontos.toFixed(0) + ' pontos';
        frmPontos.x = this.xPontos;
        frmPontos.w = this.wPontos;
        frmPontos.y = iniVazios;
        frmPontos.h = fimVazios - iniVazios;
        frmPontos.disciplina = disciplina;
        this.arrayPontos.push(frmPontos); //Insere no vetor de classificações
      }

      qtdPontos = 0;
      var vazios = temp.filter(function (a) {
        return a.classificacao === 'REC';
      });

      if (vazios.length > 0) {
        tmpPontos = vazios
          .filter(function (a) {
            return a.x === vma;
          })
          .map((c) => Number(c.text))
          .reduce((sum, current) => sum + current);

        var iniVazios = vazios[0].y;
        var fimVazios =
          vazios[vazios.length - 1].y + vazios[vazios.length - 1].h;
        qtdPontos += tmpPontos;
      }

      if (qtdPontos > 0) {
        frmPontos = new FrameCell();
        frmPontos.text = qtdPontos.toFixed(0) + ' pontos';
        frmPontos.x = this.xPontos;
        frmPontos.w = this.wPontos;
        frmPontos.y = iniVazios;
        frmPontos.h = fimVazios - iniVazios;
        frmPontos.disciplina = disciplina;
        this.arrayPontos.push(frmPontos); //Insere no vetor de classificações
      }
    }
  }

  imprimeBoletim(tipo: number) {
    this.populaClassificacao();
    this.populaPontos();
    this.populaTipoIndice();
    var doc = new jsPDF('p', 'mm', 'a4');
    doc.setLineWidth(0.1);
    this.imprimeCabecalho(doc, this.boletim);
    this.imprimeDadosAluno(doc, this.boletim);
    this.imprimeLegendas(doc);

    for (var i = 0; i < this.disciplinas.length; i++) {
      if (i == 3) {
        doc.addPage();
        this.imprimeCabecalho(doc, this.boletim);
      }
      if (i == 7) {
        doc.addPage();
        this.imprimeCabecalho(doc, this.boletim);
      }
      this.imprimeDisciplina(doc, this.disciplinas[i]);
    }

    if (tipo == 0) {
      //doc.output('dataurlnewwindow');
      var string = doc.output('datauristring');
      var iframe =
        "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    } else {
      var s =
        this.boletim.aluno.nome +
        '-' +
        this.boletim.periodo.nome +
        ' - ' +
        this.boletim.periodo.ano +
        '.pdf';
      //doc.output('save', s);
      doc.save(s);
    }
  }

  imprimeCabecalho(doc, boletim) {
    doc.setFontSize(10);
    doc.setFont('helvetica');
    doc.setFontStyle('bold');
    //doc.setTextColor(31, 73, 125);
    doc.setTextColor('black');
    doc.text(
      'BOLETIM ESCOLAR - ENSINO FUNDAMENTAL',
      115,
      18,
      null,
      null,
      'center'
    );
    doc.text(
      boletim.periodo.nome + ' - ' + boletim.periodo.ano,
      115,
      25,
      null,
      null,
      'center'
    );
  }

  imprimeDadosAluno(doc, boletim) {
    let imgLogo = new Image();
    let imgFoto = new Image();
    imgLogo.src = 'assets/images/LudensLogo.png';
    let arquivo =
      'assets/images/avatar/' + this.boletim.aluno.matricula + '.jpg';

    try {
      imgFoto.src =
        'assets/images/avatar/' + this.boletim.aluno.matricula + '.jpg';
    } catch {
      imgFoto.src = 'assets/images/avatar/stub.png';
    }

    let left = 43;
    let top = 30;
    let width = 150;
    let height = 5;
    doc.addImage(imgLogo, 'JPEG', 9, 12, 45, 16);
    try {
      doc.addImage(imgFoto, 'JPEG', 12, 30, 20, 25);
    } catch {
      imgFoto.src = 'assets/images/avatar/stub.png';
      doc.addImage(imgFoto, 'JPEG', 12, 30, 20, 25);
    }

    doc.setFontSize(8);
    doc.rect(left, top, width, height);
    doc.text('Aluno(a): ' + boletim.aluno.nome, left + 2, top + 4, 'left');

    if (this.resultado != '') {
      doc.text(this.resultado, left + 150, top + 4, 'right');
    }

    top += 5;
    doc.rect(left, top, width, height);
    doc.text('Turma: ' + boletim.turma.nome, left + 2, top + 4, 'left');
    top += 5;
    doc.rect(left, top, width, height);
    doc.text(
      'Data de nascimento: ' + boletim.aluno.dataNascimento,
      left + 2,
      top + 4,
      'left'
    );
    top += 5;
    doc.rect(left, top, width, height);
    doc.text(
      'Professora: ' + boletim.professor.nome,
      left + 2,
      top + 4,
      'left'
    );
    top += 5;
    doc.text(
      'A PROPOSTA PEDAGÓGICA ESTÁ EM EXECUÇÃO CONFORME O PREVISTO PELA ESCOLA',
      left + 2,
      top + 4,
      'left'
    );

  }

  imprimeLegendas(doc) {
    let left = 12;
    let top = 57;
    let width = this.xPaa;
    let height = 4;

    doc.rect(left, top, width, height);
    doc.text('LEGENDA:', left + 2, top + 3, 'left');

    doc.setFontSize(7);
    top += 4;
    doc.setFillColor('#F2F6FC');
    doc.rect(left, top, width, height, 'F');
    doc.text('PTS = Pontos', left + 2, top + 3, 'left');
    doc.text('AP = Atividade Prática', left + 50, top + 3, 'left');
    doc.text('AES = Atividade escrita em sala', left + 100, top + 3, 'left');

    top += 4;
    doc.text('PR = Prova', left + 2, top + 3, 'left');
    doc.text('PQ = Pesquisa', left + 50, top + 3, 'left');
    doc.text(
      'PAA = Percentual de aproveitamento por atividade',
      left + 100,
      top + 3,
      'left'
    );

    top += 4;
    doc.setFillColor('#F2F6FC');
    doc.rect(left, top, width, height, 'F');
    doc.text('T1 = Teste 1', left + 2, top + 3, 'left');
    doc.text('AOS = Atividade oral em sala', left + 50, top + 3, 'left');
    doc.text(
      'PAP = Percentual de aproveitamento do período',
      left + 100,
      top + 3,
      'left'
    );

    top += 4;
    doc.text('T2 = Teste 2', left + 2, top + 3, 'left');
    doc.text('AS = Atividade em sala', left + 50, top + 3, 'left');
    doc.text('F/N = Falta nota', left + 100, top + 3, 'left');

    top += 4;
    doc.setFillColor('#F2F6FC');
    doc.rect(left, top, width, height, 'F');
    doc.text('TPT = Teste de produção textual', left + 2, top + 3, 'left');
    doc.text('VMA = Valor máximo da atividade', left + 50, top + 3, 'left');
    doc.text('VAA = Valor alcançado na atividade', left + 100, top + 3, 'left');

    top += 4;
    doc.text('VTP = Valor total do período', left + 2, top + 3, 'left');
    doc.text('VTA = Valor total acumulado', left + 50, top + 3, 'left');
    doc.text(
      'STEAM = Ciências, tecnologia, engenharia, arte e matemática',
      left + 100,
      top + 3,
      'left'
    );

    top += 4;
    doc.setFillColor('#F2F6FC');
    doc.rect(left, top, width, height, 'F');
    doc.text('REC = Recuperação', left + 2, top + 3, 'left');
    doc.text('VTR = Valor total de recuperação', left + 50, top + 3, 'left');
    doc.text('NA = Não aplicável', left + 100, top + 3, 'left');

    doc.rect(left, 57, width, height + 28);
    //doc.setTextColor('black');
  }

  imprimeDisciplina(doc, disciplina: string) {
    doc.setFontSize(7);
    var line = 0;
    var rodape = 0;
    for (var cell of this.cells) {
      if (cell.disciplina == disciplina) {
        if (line == 0) line = cell.y - this.xLinhaSimples; // Desconta o cabecalho
        this.gravaCell(doc, cell.text, cell.x, cell.y, cell.w, cell.h);
        rodape = cell.y + cell.h;
      }
    }
    //line += 1;
    this.imprimeClassifDisciplina(doc, disciplina);
    this.imprimeTipoIndice(doc, disciplina);
    this.imprimePontosDisciplina(doc, disciplina);
    this.imprimeCabecalhoDisciplina(doc, disciplina, line);
    this.imprimeRodapeDisciplina(doc, disciplina, rodape);
  }

  gravaCell(doc, text: string, x: number, y: number, w: number, h: number) {
    doc.rect(x, y, w, h);
    if (text.length < 6) {
      if (x == this.xPaa) {
        doc.text(text, x + 8 - text.length, y + 3, null, null);
      } else {
        if (x == this.xVma && text == '0') {
          doc.text('N/A', x + 4 - text.length, y + 3, null, null);
        } else {
          // Se pedirem para mostar vazio, habilita este trecho.
          // if (text == '0' || text == '0.0'){
          //   text = '';
          // }

          // JBS tratamento para recuperação
          if (text == 'PR 1' || text == 'PR 2') {
            doc.text(text, x + 8 - text.length, y + 3, null, null);
          } else {
            doc.text(text, x + 6 - text.length, y + 3, null, null);
          }
        }
      }
    } else {
      var splitText = doc.splitTextToSize(text, this.textLineWidth);
      doc.text(splitText, x + 4, y + 3, null, null);
    }
  }

  gravaPontos(doc, text: string, x: number, y: number, w: number, h: number) {
    doc.rect(x, y, w, h);
    doc.text(text, x + 0.5, y + h / 2 + 1, null, null);
  }

  gravaIndices(doc, text: string, x: number, y: number, w: number, h: number) {
    doc.setFontSize(7);
    if (text.length > 0) {
      doc.rect(x, y, w, h);
    }
    if (text.length > 3) {
      var splitText = doc.splitTextToSize(text, 13);
      doc.text(splitText, x + w / 2, y + h / 2, null, null, 'center');
    }
    else if (text == 'REC' && h > 8) {
      doc.text(text, x + w / 2, y + h / 2 + 4, null, null, 'center');
    } else {
      doc.text(text, x + w / 2, y + h / 2 + 1, null, null, 'center');
    }
  }

  imprimeClassifDisciplina(doc, disciplina: string) {
    for (var cell of this.arrayClassif) {
      if (cell.disciplina == disciplina) {
        this.gravaIndices(doc, cell.text, cell.x, cell.y, cell.w, cell.h);
      }
    }
  }

  imprimePontosDisciplina(doc, disciplina: string) {
    for (var cell of this.arrayPontos) {
      if (cell.disciplina == disciplina) {
        this.gravaPontos(doc, cell.text, cell.x, cell.y, cell.w, cell.h);
      }
    }
  }

  imprimeTipoIndice(doc, disciplina) {
    for (var cell of this.tipoIndice) {
      if (cell.disciplina == disciplina) {
        this.gravaIndices(doc, cell.text, cell.x, cell.y, cell.w, cell.h);
      }
    }
  }

  imprimeCabecalhoDisciplina(doc, disciplina: string, line: number) {
    var fontSize = doc.fontSize;
    doc.setFontSize(7);

    doc.setFillColor('#f7a83a');
    doc.rect(12, line, 151, 4, 'F');
    doc.rect(12, line, 151, 4);
    doc.text(
      'Componente curricular - ' + disciplina,
      82,
      line + 3,
      null,
      null,
      'center'
    );
    doc.setFillColor('#b7d0f7');

    doc.rect(this.xVma, line, 9, 4, 'F');
    doc.rect(this.xVma, line, 9, 4);
    doc.text('VMA', 165, line + 3, null, null, 'left');
    doc.setFillColor('#b7d0f7');

    doc.rect(this.xVaa, line, 9, 4, 'F');
    doc.rect(this.xVaa, line, 9, 4);
    doc.text('VAA', 174, line + 3, null, null, 'left');
    doc.setFillColor('#b7d0f7');

    doc.rect(this.xPaa, line, 12, 4, 'F');
    doc.rect(this.xPaa, line, 12, 4);
    doc.text('PAA-(%)', 182, line + 3, null, null, 'left');
    doc.setFontSize(fontSize);
  }

  imprimeRodapeDisciplina(doc, disciplina: string, endLine: number) {
    var vaa = this.xVaa;
    var vma = this.xVma;
    var totVTA: string;
    var totPAP: string;
    var temp = this.cells.filter(function (d) {
      return d.disciplina === disciplina;
    });

    if (temp.length > 0) {
      var totVMA = temp
        .filter(function (a) {
          return a.x === vma && a.classificacao != 'REC';
        })
        .map((c) => Number(c.text))
        .reduce((sum, current) => sum + current);

      var tmpTotVAA = temp
        .filter(function (a) {
          return a.x === vaa && a.classificacao != 'REC';
        })
        .map((c) => Number(c.text == 'F/N' ? '0' : c.text))
        .reduce((sum, current) => sum + current);

      try {
        var totVTR = temp
          .filter(function (a) {
            return a.x === vaa && a.classificacao === 'REC';
          })
          .map((c) => Number(c.text == 'F/N' ? '0' : c.text))
          .reduce((sum, current) => sum + current);
      } catch {
        var totVTR = 0;
      }

      // Só adiciona as notas de índice formativo se tiver feito alguma prova de recuperação
      if (totVTR > 0) {
        totVTR = temp
          .filter(function (a) {
            return (
              a.x === vaa &&
              (a.classificacao === 'REC' || a.classificacao === '')
            );
          })
          .map((c) => Number(c.text == 'F/N' ? '0' : c.text))
          .reduce((sum, current) => sum + current);
      }

      // JBS Se as notas de recuperação forem maiores dos que as inicias, substituir pelas notas de recuperação.
      tmpTotVAA = tmpTotVAA >= totVTR ? tmpTotVAA : totVTR;

      var pap = (tmpTotVAA / totVMA) * 100;
      var vpap = Number(pap).toFixed(2);
      var totVAA = Number(tmpTotVAA).toFixed(2);

      var totPeriodo = this.totaisPeriodo.filter(function (d) {
        return d.disciplina === disciplina;
      });
      totVTA = Number(totPeriodo[0].somaVaa).toFixed(2);
      totPAP = Number(
        (totPeriodo[0].somaVaa / totPeriodo[0].somaVma) * 100
      ).toFixed(2);
    }

    doc.setFontSize(7);
    doc.rect(12, endLine, 151, 8);
    doc.text('Valor total do período', 15, endLine + 5, null, null, 'left');

    doc.rect(this.xVma, endLine, 9, 4);
    doc.rect(this.xPaa, endLine, 12, 4);
    doc.rect(this.xVma, endLine + 4, 9, 4);
    doc.rect(this.xVma, endLine + 4, 18, 4);
    doc.rect(this.xPaa, endLine + 4, 12, 4);

    doc.text('VTP', this.xVma + 4.5, endLine + 3, null, null, 'center');
    doc.setTextColor('red');
    doc.text('VTR', this.xVma + 13.5, endLine + 3, null, null, 'center');
    doc.setTextColor('black');
    doc.text('PAP', 188, endLine + 3, null, null, 'center');
    doc.text(
      totVAA.toString(),
      this.xVma + 4.5,
      endLine + 7,
      null,
      null,
      'center'
    );
    doc.setTextColor('red');
    doc.text(
      totVTR.toString(),
      this.xVma + 14,
      endLine + 7,
      null,
      null,
      'center'
    );
    doc.setTextColor('black');
    doc.text(vpap.toString(), 188, endLine + 7, null, null, 'center');

    endLine += 3;

    // JBS inclusão de totalizadores
    doc.rect(12, endLine + 5, 151, 8);
    doc.text('Valor total acumulado', 15, endLine + 10, null, null, 'left');

    doc.rect(this.xVma, endLine + 5, 18, 4);
    doc.rect(this.xPaa, endLine + 5, 12, 4);
    doc.rect(this.xVma, endLine + 9, 18, 4);
    doc.rect(this.xPaa, endLine + 9, 12, 4);
    doc.text('VTA', 173, endLine + 8, null, null, 'center');
    doc.text('PAP', 188, endLine + 8, null, null, 'center');
    doc.text(totVTA.toString(), 173, endLine + 12, null, null, 'center');
    doc.text(totPAP.toString(), 188, endLine + 12, null, null, 'center');
  }
}
