<mat-card class="example-card mat-elevation-z4 card-container">
    <mat-card-title>
      <mat-icon>print</mat-icon> Imprimir Boletim
    </mat-card-title>
    <hr /> 
  
    <mat-form-field class="col-md-5">
      <mat-select [(ngModel)]="idPeriodo" (selectionChange)='filtraTurma(idPeriodo)' placeholder="Período...">
        <mat-option *ngFor="let periodo of periodos" [value]="periodo.id">
          {{periodo.nome}} - {{periodo.ano}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field class="col-md-2">
      <mat-select [(ngModel)]="idTurma"  (selectionChange)='filtraAlunos(idTurma)' [disabled]="turmaInativa" placeholder="Turma...">
        <mat-option *ngFor="let turma of turmas" [value]="turma.id">
          {{turma.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div row>
      <mat-form-field class="col-md-8">
        <mat-select [(ngModel)]="idAluno" [disabled]="alunoInativo" placeholder="Aluno...">
          <mat-option *ngFor="let aluno of alunos" [value]="aluno.id">
            {{aluno.nome}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="imprimirBoletins(idTipoImpressao = 0)">
        <mat-icon>search</mat-icon> Visualizar
      </button>
      <button mat-raised-button color="primary" (click)="imprimirBoletins(idTipoImpressao = 1)">
        <mat-icon>print</mat-icon> Gerar Pdf
      </button>
    </div>  
  </mat-card>
  