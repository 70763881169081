import { Component, OnInit } from '@angular/core';
import { PeriodoService } from 'src/app/services/periodo.service';
import { Periodo } from 'src/app/models/periodo';
import { DisciplinaService } from 'src/app/services/disciplina.service';
import { TipoAtividadeService } from 'src/app/services/tipo-atividade.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { Atividade } from 'src/app/models/atividade';
import { SnackBar } from 'src/app/_helpers/snackBar';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/_utils/Confirmation-Dialog/confirmation-dialag';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TurmaService } from 'src/app/services/turma.service';


@Component({
  selector: 'app-atividade-form',
  templateUrl: './atividade-form.component.html',
  styleUrls: ['./atividade-form.component.css']
})
export class AtividadeFormComponent implements OnInit {
  private readonly PAGE_SIZE = 5;

  id: string;
  atividade: Atividade = {
    id: 0,
    disciplinaId: 0,
    tipoAtividadeId: 0,
    periodoId: 0,
    turmaId: 0,
    vma: 0,
    classificacao: '',
    ordem: 0
  };

  idAtividade: number;
  idDisciplina: number;
  idTipoAtividade: number;
  idPeriodo: number;
  idTurma: number;  
  vma: number;
  classificacao: string;
  ordem: number;

  //periodos: Periodo[];
  periodos = [];
  turmas = [];
  disciplinas = [];
  tipoAtividades = [];

  disciplinaSel = 0;
  periodoSel = 0;
  turmaSel = 0;
  tipoAtividadeSel = 0;

  query: any = {
    pageSize: this.PAGE_SIZE
  };

  dialogRef: MatDialogRef<ConfirmationDialog>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private disciplinaService: DisciplinaService,
    private tipoAtividadeService: TipoAtividadeService,
    private periodoService: PeriodoService,
    private turmaService: TurmaService,
    private atividadeService: AtividadeService,
    public dialog: MatDialog,
    private snackBar: SnackBar,
    private authenticationService: AuthenticationService

  ) {
    this.disciplinaService.getDisciplinas(this.query).subscribe(
      result => {
        this.disciplinas = result;
      }
    )

    this.tipoAtividadeService.getTipoAtividades(this.query).subscribe(
      result => {
        this.tipoAtividades = result;
      }
    )
    this.periodoService.getPeriodos(this.query).subscribe(
      result => {
        this.periodos = result;
      });
      this.turmaService.getTurmas(this.query).subscribe(
        result => {
          this.turmas = result;
        });      

  }

  ngOnInit() {
    if (!this.authenticationService.IsLoggedIn()) {
      this.router.navigate(['/login']);
    }
    else {
      this.id = this.route.snapshot.params['id'];
      if (this.id != null) {
        this.atividadeService.getAtividade(this.id)
          .subscribe(result => {
            this.atividade = result;
            this.disciplinaSel = this.atividade.disciplinaId;
            this.periodoSel = this.atividade.periodoId;
            this.turmaSel = this.atividade.turmaId;
            this.tipoAtividadeSel = this.atividade.tipoAtividadeId;
          },
            error => {
              this.snackBar.openSnackBar('Erro ao carregar dados!', 'Ok', 5000);
            });
      }
    }
  }

  submit() {
    this.atividade.disciplinaId = this.disciplinaSel;
    this.atividade.periodoId = this.periodoSel;
    this.atividade.turmaId = this.turmaSel;
    this.atividade.tipoAtividadeId = this.tipoAtividadeSel;

    if (this.atividade.id) {

      this.atividadeService.updateAtividade(this.atividade)
        .subscribe(x => {
          this.snackBar.openSnackBar('Dados gravados com sucesso!', 'Ok', 2000);
        },
          error => {
            this.snackBar.openSnackBar('Erro ao gravar dados!', 'Ok', 5000);
          });
    } else {
      this.atividadeService.createAtividade(this.atividade)
        .subscribe(x => {
          this.snackBar.openSnackBar('Dados gravados com sucesso!', 'Ok', 2000);
        },
          error => {
            this.snackBar.openSnackBar('Erro ao gravar dados!', 'Ok', 5000);
          });
    }
  }

  delete() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Tem certeza que deseja excluir?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.atividadeService.deleteAtividade(this.atividade.id)
          .subscribe(x => {
            this.router.navigate(['/atividades']);
          },
            error => {
              this.snackBar.openSnackBar('Erro ao gravar dados!', 'Ok', 5000);
            });
      }
      this.dialogRef = null;
    });
  }
  back() {
    window.history.back();
  }
}

