import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AlunoListComponent } from './components/aluno-list/aluno-list.component';
import { ProfessorListComponent } from './components/professor-list/professor-list.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlunoFormComponent } from './components/aluno-form/aluno-form.component';
import { BoletimListComponent } from './components/boletim-list/boletim-list.component';
import { BoletimFormComponent } from './components/boletim-form/boletim-form.component';
import { TipoAtividadeComponent } from './components/tipo-atividade/tipo-atividade.component';
import { AtividadeComponent } from './components/atividade/atividade.component';
import { AtividadeFormComponent } from './components/atividade-form/atividade-form.component';


const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent},
  { path: 'alunos', component: AlunoListComponent, canActivate: [AuthGuard] },
  { path: 'alunos/add', component: AlunoFormComponent, canActivate: [AuthGuard] },
  { path: 'alunos/:id', component: AlunoFormComponent, canActivate: [AuthGuard]  },
  { path: 'boletins', component: BoletimListComponent, canActivate: [AuthGuard] },
  { path: 'boletins/:id', component: BoletimFormComponent, canActivate: [AuthGuard]  },
  { path: 'imprimirBoletins', component: BoletimFormComponent, canActivate: [AuthGuard]  },
  { path: 'tipoAtividades', component: TipoAtividadeComponent, canActivate: [AuthGuard] },
  { path: 'atividades', component: AtividadeComponent, canActivate: [AuthGuard] },
  { path: 'atividades/add', component: AtividadeFormComponent, canActivate: [AuthGuard] },
  { path: 'atividades/:id', component: AtividadeFormComponent, canActivate: [AuthGuard] },
  { path: 'professores', component: ProfessorListComponent, canActivate: [AuthGuard] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
