export class Contadores {
    lines?: number;
    classificacao?: number;
    tipoAtividade?: number;
    
    totVMA?: number;
    totVAA?: number;
    tipoIndice?: number;
    somaPontos?: number;

    disciplina?: string;
    txtClassif?: string;
    txtPontos?: string;
    txtTipoIndice?: string;

    clear(){
        this.lines = 0;
        this.classificacao = 0;
        this.tipoAtividade = 0;
        
        this.totVMA = 0;
        this.totVAA = 0;
        this.tipoIndice = 0;
        this.somaPontos = 0;
    
        this.disciplina = '';
        this.txtClassif = '';
        this.txtPontos = ''
        this.txtTipoIndice = '';
    }
  }
  
  