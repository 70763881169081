import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { spinHttpInterceptor } from './_helpers/spinHttpInterceptor';

import {NgxMaskModule} from 'ngx-mask';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import 'hammerjs';
import { AlunoListComponent } from './components/aluno-list/aluno-list.component';
import { ProfessorListComponent } from './components/professor-list/professor-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AlunoService } from './services/aluno.service';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { AlunoFormComponent } from './components/aluno-form/aluno-form.component';
import { ConfirmationDialog } from './_utils/Confirmation-Dialog/confirmation-dialag';
import { BoletimListComponent } from './components/boletim-list/boletim-list.component';
import { BoletimService } from './services/boletim.service';
import { PeriodoService } from './services/periodo.service';
import { TurmaService } from './services/turma.service';
import { DisciplinaService } from './services/disciplina.service';
import { registerLocaleData } from '@angular/common';
import { BoletimFormComponent } from './components/boletim-form/boletim-form.component';
import { TipoAtividadeComponent } from './components/tipo-atividade/tipo-atividade.component';
import { AtividadeComponent } from './components/atividade/atividade.component';
import { SnackBar } from './_helpers/snackBar';
import { AtividadeFormComponent } from './components/atividade-form/atividade-form.component';
import { FramePdfService } from './services/frame-pdf.service';
import { FrameCell } from './models/frameCell';
import { PrintPdfService } from './services/print-pdf.service';
import { MatNativeDateModule } from '@angular/material/core';


registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    ProfessorListComponent,
    DashboardComponent,
    LoginComponent,
    AlunoListComponent,
    AlunoFormComponent,
    ConfirmationDialog,
    BoletimListComponent,
    BoletimFormComponent,
    TipoAtividadeComponent,
    AtividadeComponent,
    AtividadeFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LayoutModule,
    FlexLayoutModule,
    NgxDatatableModule,
    NgxPaginationModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatGridListModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatDialogModule,
    OverlayModule,
    FormsModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    AlunoService,
    BoletimService,
    PeriodoService,
    TurmaService,
    DisciplinaService,
    SnackBar,
    FramePdfService,
    PrintPdfService,
    FrameCell,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: spinHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents: [
    ConfirmationDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
