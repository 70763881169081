import { Disciplina } from './../models/disciplina';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DisciplinaService {
  public disciplinas = [];
  private readonly endpoint = environment.apiUrl + '/api/disciplinas';

  constructor(private http: HttpClient) {

  }

  getDisciplinas(filter): Observable<Disciplina[]> {  
    return this.http.get<Disciplina[]>(this.endpoint);
  } 

    getDisciplina(id: String): Observable<Disciplina> {
      return this.http.get<Disciplina>(this.endpoint + '/' + id); 
    } 

    updateDisciplina(disciplina: Disciplina) {
      return this.http.put(this.endpoint + '/' + disciplina.id, disciplina);
      // return this.http.put(this.endpoint + '/' + disciplina.id, disciplina)
      // .map((data: any[]) => {
      //     this.disciplinas = data;
      //     return true;
      // })
    }

    createDisciplina(disciplina) {
      return this.http.post(this.endpoint, disciplina);
      // return this.http.post(this.endpoint, disciplina)
      // .map((data: any[]) => {
      //     this.disciplinas = data;
      //     return true;
      // })
    }
    deleteDisciplina(id) {
      return this.http.delete(this.endpoint + '/' + id);
      // return this.http.delete(this.endpoint + '/' + id)
      // .map((data: any[]) => {
      //     this.disciplinas = data;
      //     return true;
      // })
    }
}