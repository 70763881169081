import { Nota } from './../models/nota';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class NotaService {

  public error: any;
  public notas = [];

  private readonly endpoint = environment.apiUrl + '/api/notas';

  constructor(private http: HttpClient) {

  }

  getNotas(filter): Observable<Nota[]> {
    return this.http.get<Nota[]>(this.endpoint + '?' + this.toQueryString(filter))
  }

  getTotaisPeriodo(filter): Observable<Nota[]> {
    return this.http.get<Nota[]>(environment.apiUrl + '/api/totais' + '?' + this.toQueryString(filter))
  }


  toQueryString(obj) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

  getNota(id: String): Observable<Nota> {
    return this.http.get<Nota>(this.endpoint + '/' + id);
  }

  updateNota(nota: Nota) {
    return this.http.put(this.endpoint + '/' + nota.id, nota);
    // return this.http.put(this.endpoint + '/' + nota.id, nota)
    //   .map((data: any[]) => {
    //     this.notas = data;
    //     return true;
    //   },
    //     error => {
    //       error => this.error = error;
    //       console.log(error);
    //     });
  }

  createNota(nota) {
    return this.http.post(this.endpoint, nota);
    // return this.http.post(this.endpoint, nota)
    //   .map((data: any[]) => {
    //     this.notas = data;
    //     return true;
    //   })
  }
  deleteNota(id) {
    return this.http.delete(this.endpoint + '/' + id);
    // return this.http.delete(this.endpoint + '/' + id)
    //   .map((data: any[]) => {
    //     this.notas = data;
    //     return true;
    //   })
  }
}