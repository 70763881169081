<mat-card class="example-card mat-elevation-z4 card-container">
  <mat-card-title>
    <mat-icon>assignment</mat-icon> Lançamento de notas
  </mat-card-title>
  <hr />
  <mat-form-field class="col-md-5">
    <mat-select [(ngModel)]="idPeriodo" [disabled]="alterando" (selectionChange)='filtraTurma(idPeriodo)' placeholder="Período...">
      <mat-option *ngFor="let periodo of periodos" [value]="periodo.id">
        {{periodo.nome}} - {{periodo.ano}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-md-2">
    <mat-select [(ngModel)]="idTurma" [disabled]="alterando" (selectionChange)='filtraAlunos(idTurma)' placeholder="Turma...">
      <mat-option *ngFor="let turma of turmas" [value]="turma.id">
        {{turma.nome}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-md-5">
      <mat-select [(ngModel)]="idDisciplina" [disabled]="alterando" placeholder="Disciplina...">
        <mat-option *ngFor="let disciplina of disciplinas" [value]="disciplina.id">
          {{disciplina.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>  

  <div row>
    <mat-form-field class="col-md-9">
      <mat-select [(ngModel)]="idAluno" [disabled]="alterando" (selectionChange)='alunoSelecionado(idAluno)' placeholder="Aluno...">
        <mat-option *ngFor="let aluno of alunos" [value]="aluno.id">
          {{aluno.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color = "primary" [disabled]="semAlunoSel" (click)="buscarNotas()">
      <mat-icon>search</mat-icon>Filtrar
    </button>
  </div>

  <div *ngIf="rows.length > 0;else semDados" class="mat-elevation-z1">
    <ngx-datatable #table class="material striped" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      [rowHeight]="50" [scrollbarV]="false" [scrollbarH]="false" [limit]="5" [rows]="rows">
      <ngx-datatable-column name="Disciplina" prop="atividade.disciplina.nome" [width]="30"></ngx-datatable-column>
      <ngx-datatable-column name="Índice" prop="atividade.tipoAtividade.tipoIndice" [width]="10"></ngx-datatable-column>
      <ngx-datatable-column name="Atividade" prop="atividade.tipoAtividade.nome" [width]="150"></ngx-datatable-column>
      <ngx-datatable-column name="VMA" prop="atividade.vma" [width]="5"></ngx-datatable-column>      

      <ngx-datatable-column name="F/N" prop="fn" [width]="3">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
          let-rowHeight="rowHeight">
          <mat-checkbox (change)="setPA(rowIndex, value)" [checked]="value == 'T'"></mat-checkbox>          
        </ng-template>
      </ngx-datatable-column>            

      <ngx-datatable-column name="VAA" prop="vaa" [width]="3">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
          let-rowHeight="rowHeight">
          <!-- <input class="cell-width" mask="0*.00" (blur)="updateValue($event, 'vaa', rowIndex)" type="text" name="vaa" Máscara parou de funcionar em angular 9-->
          <input class="cell-width" (blur)="updateValue($event, 'vaa', rowIndex)" type="text" name="vaa"
            [value]="value" />
        </ng-template>
      </ngx-datatable-column>      

      <ngx-datatable-column name="PAA(%)" prop="paa" [width]="5">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <ng-template #semDados>
    <hr>
    <h4>Preencha os filtros...</h4>
  </ng-template>
  <hr />
  <div row>
    <button *ngIf="temDados" color = "primary" [disabled]="!alterando" mat-raised-button (click)="salvar()">
      <mat-icon>done</mat-icon> Salvar
    </button>
    <button *ngIf="temDados" color = "warn" [disabled]="!alterando" mat-raised-button (click)="cancelar()">
      <mat-icon>cancel</mat-icon> Cancelar
    </button>
  </div>

</mat-card>
