<form #f="ngForm" (ngSubmit)="submit()" novalidate>
  <mat-card class="example-card mat-elevation-z4 card-container">
    <mat-card-title>
      <mat-icon>library_books</mat-icon> Atividades
    </mat-card-title>

    <mat-form-field class="col-md-5">
      <mat-select [(ngModel)]="disciplinaSel" name="disciplinas.nome" placeholder="Disciplina...">
        <mat-option *ngFor="let disciplina of disciplinas" [value]="disciplina.id">
          {{disciplina.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-5">
      <mat-select [(ngModel)]="periodoSel" name="periodos.nome" placeholder="Período...">
        <mat-option *ngFor="let periodo of periodos" [value]="periodo.id">
      {{periodo.nome}} - {{periodo.ano}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-2">
      <mat-select [(ngModel)]="turmaSel" name="turmas.nome" placeholder="Turma...">
        <mat-option *ngFor="let turma of turmas" [value]="turma.id">
          {{turma.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-8">
      <mat-select [(ngModel)]="tipoAtividadeSel" name="tipoAtividades.nome" placeholder="Tipo de Atividade...">
        <mat-option *ngFor="let tipoAtividade of tipoAtividades" [value]="tipoAtividade.id">
          {{tipoAtividade.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div row>
      <mat-form-field class="col-md-3">
        <input [(ngModel)]="atividade.vma" name="vma" matInput placeholder="vma"placeholder="VMA">
      </mat-form-field>

      <mat-form-field class="col-md-3">
        <input [(ngModel)]="atividade.classificacao" name="classificacao" matInput placeholder="Classificação">
      </mat-form-field>

      <mat-form-field class="col-md-2">
        <input [(ngModel)]="atividade.ordem" name="ordem" matInput placeholder="Ordem">
      </mat-form-field>
      <button mat-raised-button class="bg-customPrimary text-black" type="submit" value="Submit">Salvar</button>
      <button mat-raised-button class="bg-info text-white" type="button" (click)="back()">Voltar</button>
      <button mat-raised-button *ngIf="atividade.id" class="bg-danger text-white" type="button"
        (click)="delete()">Excluir</button>
    </div>
  </mat-card>
</form>
