import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Atividade } from '../models/atividade';


@Injectable({
  providedIn: 'root'
})
export class AtividadeService {
  public atividades = [];
  private readonly endpoint = environment.apiUrl + '/api/atividades';

  constructor(private http: HttpClient) {

  }

  getAtividades(filter): Observable<Atividade[]> {    
    return this.http.get<Atividade[]>(this.endpoint + '?' + this.toQueryString(filter));

  }

  toQueryString(obj) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined) 
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

  getAtividade(id: String): Observable<Atividade> {
    return this.http.get<Atividade>(this.endpoint + '/' + id);
  }

  updateAtividade(atividade: Atividade) {
    return this.http.put(this.endpoint + '/' + atividade.id, atividade);
    // return this.http.put(this.endpoint + '/' + atividade.id, atividade)
    //   .map((data: any[]) => {
    //     this.atividades = data;
    //     return true;
    //   })
  }

  createAtividade(atividade) {
    return this.http.post(this.endpoint, atividade);
    // return this.http.post(this.endpoint, atividade)
    //   .map((data: any[]) => {
    //     this.atividades = data;
    //     return true;
    //   })
  }

  deleteAtividade(id) {
    return this.http.delete(this.endpoint + '/' + id);
    // return this.http.delete(this.endpoint + '/' + id)
    //   .map((data: any[]) => {
    //     this.atividades = data;
    //     return true;
    //   })
  }
}