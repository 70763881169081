import { Component, OnInit } from '@angular/core';
import { Aluno } from 'src/app/models/aluno';
import { AlunoService } from 'src/app/services/aluno.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { ConfirmationDialog } from 'src/app/_utils/Confirmation-Dialog/confirmation-dialag';


@Component({
  selector: 'app-aluno-form',
  templateUrl: './aluno-form.component.html',
  styleUrls: ['./aluno-form.component.css']
})
export class AlunoFormComponent implements OnInit {

  
  id: string;
  aluno: Aluno = {
    nome: '',
    matricula: '',
    dataNascimento: ''
  };


  dialogRef: MatDialogRef<ConfirmationDialog>;

  constructor(
    private alunoService: AlunoService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {

   }

   ngOnInit() {
    if (!this.authenticationService.IsLoggedIn()){      
      this.router.navigate(['/login']);
    }
    else{
      this.id = this.route.snapshot.params['id'];
      if (this.id != null) {
        this.alunoService.getAluno(this.id)
        .subscribe(result => {        
        this.aluno = result;
        },
        error =>{
          this.openSnackBar('Erro ao carregar dados!','Ok', 5000);
        });
      }
    }
  }

  submit() {      
    if (this.aluno.id) {

      this.alunoService.updateAluno(this.aluno)
        .subscribe(x => {
          this.openSnackBar('Dados gravados com sucesso!','Ok', 2000);
        },
        error =>{
          this.openSnackBar('Erro ao gravar dados!','Ok', 5000);
        });        
    } else {
      this.alunoService.createAluno(this.aluno)
        .subscribe(x => {
          this.openSnackBar('Dados gravados com sucesso!','Ok', 2000);
        },
        error =>{
          this.openSnackBar('Erro ao gravar dados!','Ok', 5000);
        });        
    }
  }  
  
  delete() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Tem certeza que deseja excluir?"
    this.dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.alunoService.deleteAluno(this.aluno.id)
          .subscribe(x => {
          this.router.navigate(['/alunos']);
        },
        error =>{
          this.openSnackBar('Erro ao gravar dados!','Ok', 5000);
        }); 
      }
      this.dialogRef = null;
    });
  }
  back(){
    window.history.back();
  }

  openSnackBar(message: string, action: string, tempo: number) {
    this.snackBar.open(message, action, {
        duration: tempo,
    });
}

}
