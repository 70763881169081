import { Aluno } from './../models/aluno';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Boletim } from '../models/boletim';


@Injectable({
  providedIn: 'root'
})
export class AlunoService {
  private readonly PAGE_SIZE = 5;
    public alunos = [];
    private readonly endpoint = environment.apiUrl + '/api/alunos';
    //private readonly epBoletim = environment.apiUrl + '/api/alunos/';

    query: any = {
      pageSize: this.PAGE_SIZE
    };
  

    constructor(private http: HttpClient) {

    }

    // getAlunos(filter) {      
    //     return this.http.get(this.endpoint + '?' + this.toQueryString(filter))
    //         .map((data: any[]) => {
    //             this.alunos = data;
    //             return this.alunos;
    //         })
    // }

    toQueryString(obj) {
        var parts = [];
        for (var property in obj) {
          var value = obj[property];
          if (value != null && value != undefined) 
            parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
        }
        return parts.join('&');
      }    

    getAlunos(filter): Observable<Aluno[]> {
      return this.http.get<Aluno[]>(this.endpoint);
    } 

    getAlunosTurma(filter): Observable<Aluno[]> {
      return this.http.get<Boletim[]>(this.endpoint + '/alunosTurma?' + this.toQueryString(filter));
    }     

      getAluno(id: String): Observable<Aluno> {
        return this.http.get<Aluno>(this.endpoint + '/' + id); 
      } 

      updateAluno(aluno: Aluno) {
        return this.http.put(this.endpoint + '/' + aluno.id, aluno);
        // return this.http.put(this.endpoint + '/' + aluno.id, aluno)
        // .map((data: any[]) => {
        //     this.alunos = data;
        //     return true;
        // })
      }

      createAluno(aluno) {
        return this.http.post(this.endpoint, aluno);
        // return this.http.post(this.endpoint, aluno)
        // .map((data: any[]) => {
        //     this.alunos = data;
        //     return true;
        // })
      }
      deleteAluno(id) {
        return this.http.delete(this.endpoint + '/' + id);
        // return this.http.delete(this.endpoint + '/' + id)
        // .map((data: any[]) => {
        //     this.alunos = data;
        //     return true;
        // })
      }
}