import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TipoAtividade } from '../models/tipoAtividade';


@Injectable({
  providedIn: 'root'
})
export class TipoAtividadeService {
  public atividades = [];
  private readonly endpoint = environment.apiUrl + '/api/tipoAtividades';

  constructor(private http: HttpClient) {

  }

  getTipoAtividades(filter): Observable<TipoAtividade[]> {
    return this.http.get<TipoAtividade[]>(this.endpoint);
  }

  getTipoAtividade(id: String): Observable<TipoAtividade> {
    return this.http.get<TipoAtividade>(this.endpoint + '/' + id);
  }

  updateTipoAtividade(tipoAtividade: TipoAtividade) {
    return this.http.put(this.endpoint + '/' + tipoAtividade.id, tipoAtividade);
    // return this.http.put(this.endpoint + '/' + tipoAtividade.id, tipoAtividade)
    //   .map((data: any[]) => {
    //     this.atividades = data;
    //     return true;
    //   })
  }

  createTipoAtividade(tipoAtividade) {
    return this.http.post(this.endpoint, tipoAtividade);
    // return this.http.post(this.endpoint, tipoAtividade)
    //   .map((data: any[]) => {
    //     this.atividades = data;
    //     return true;
    //   })
  }
  deleteTipoAtividade(id) {
    return this.http.delete(this.endpoint + '/' + id);
    // return this.http.delete(this.endpoint + '/' + id)
    //   .map((data: any[]) => {
    //     this.atividades = data;
    //     return true;
    //   })
  }
}