import { Component, OnInit } from '@angular/core';
import { PeriodoService } from 'src/app/services/periodo.service';
import { Periodo } from 'src/app/models/periodo';
import { DisciplinaService } from 'src/app/services/disciplina.service';
import { TipoAtividadeService } from 'src/app/services/tipo-atividade.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { Router } from '@angular/router';
import { TurmaService } from 'src/app/services/turma.service';


@Component({
  selector: 'app-atividade',
  templateUrl: './atividade.component.html',
  styleUrls: ['./atividade.component.css']
})
export class AtividadeComponent implements OnInit {
  private readonly PAGE_SIZE = 5;

 
  temDados: boolean = false;
  alterando: boolean = false;

  idAtividade: number;
  idDisciplina: number;
  idTipoAtividade: number;
  idTurma: number;
  idPeriodo: number;
  vma: number;
  classificacao: string;
  ordem: number;

  rows = [];
  temp = [];

  periodos: Periodo[];
  disciplinas = [];
  tipoAtividades = [];
  turmas = [];
  //atividades = [];


  query: any = {
    pageSize: this.PAGE_SIZE,    
    idDisciplina: this.idDisciplina,
    idTipoAtividade: this.idTipoAtividade,
    idPeriodo: this.idPeriodo

  };

  constructor(
    private router: Router,
    private disciplinaService: DisciplinaService,
    private tipoAtividadeService: TipoAtividadeService,
    private periodoService: PeriodoService,
    private turmaService: TurmaService,
    private atividadeService: AtividadeService,    
  ) {
    this.disciplinaService.getDisciplinas(this.query).subscribe(
      result => {
        this.disciplinas = result;
      }
    )

    this.tipoAtividadeService.getTipoAtividades(this.query).subscribe(
      result => {
        this.tipoAtividades = result;
      }
    )
    this.periodoService.getPeriodos(this.query).subscribe(
      result => {
        this.periodos = result;
      });
      this.turmaService.getTurmas(this.query).subscribe(
        result => {
          this.turmas = result;
        });
  
  }

  ngOnInit() {    
  }
  
  edit(row) {
    this.router.navigate(['/atividades/' + row.id]);
    
  }
  filtrar(){
    this.query.idDisciplina = this.idDisciplina;
    this.query.idPeriodo = this.idPeriodo;
    this.query.idTipoAtividade = this.idTipoAtividade;
    this.query.idTurma = this.idTurma;
    this.atividadeService.getAtividades(this.query).subscribe(
      result => {
        this.rows = result;
      }
    )
  }  

}

