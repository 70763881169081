<mat-card class="example-card mat-elevation-z4 card-container">
  <mat-card-title>
    <mat-icon>school</mat-icon> Alunos
  </mat-card-title>
  <hr />
  <div class="row">
    <div class="col-md-7">
      <button mat-raised-button color="primary" [routerLink]="['/alunos/add']">
        <mat-icon>add</mat-icon>Adicionar
      </button>
    </div>
    <mat-form-field class="col-md-5">
      <input matInput [(ngModel)]="query.filter" (keyup)="updateFilter($event)" placeholder="Buscar">
    </mat-form-field>
  </div>
  <hr />
  <div *ngIf="rows.length > 0;else semDados" class="mat-elevation-z1">
    <ngx-datatable #table class="material striped" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      [rowHeight]="50" [scrollbarV]="false" [scrollbarH]="false" [limit]="6" [rows]="rows">
      <ngx-datatable-column name="" [width]="1" prop="Id" [sortable]="false">

        <ng-template let-row="row" let-column="column" let-value="value" ngx-datatable-cell-template>
          <button mat-icon-button>
            <mat-icon (click)="edit(row)">edit</mat-icon>
          </button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Matrícula" prop="matricula" [width]="30"></ngx-datatable-column>
      <ngx-datatable-column name="Nome do Aluno" prop="nome" [width]="100"></ngx-datatable-column>
      <ngx-datatable-column name="Data de Nascimento" prop="dataNascimento" [width]="100"></ngx-datatable-column>
    </ngx-datatable>
  </div>
  <ng-template #semDados>
    <hr>
    <h4>Carregando dados...</h4>
  </ng-template>
</mat-card>
