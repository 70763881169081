export class FrameCell {
  x?: number;
  y?: number;
  w?: number;
  h?: number;
  boletimId?: number;
  pontos?: number;
  text?: string;
  classificacao?: string;
  tipoIndice?: string;
  disciplina?: string;
  
  
  clear(){
    this.x = 0;
    this.y = 0;
    this.w = 0;
    this.h = 0;
    this.text = '';
    this.classificacao = '';
    this.tipoIndice = '';    
    this.disciplina = '';
  }

}