<main-nav>
  <router-outlet>
                  <ngx-spinner
                  bdColor="rgba(51,51,51,0.3)"
                  size="medium"
                  color="#fff"
                  type="ball-clip-rotate">
                 <p style="font-size: 20px; color: white">Processando...</p>
                </ngx-spinner>
  </router-outlet>
</main-nav>


