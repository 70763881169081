import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    returnUrl: string;
    error = '';
    username: string;
    password: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    login() {
        if (this.username == null || this.password == null) {
            this.openSnackBar('Usuário e senha obrigatórios', 'Ok', 5000);
            return;
        }
        this.authenticationService.login(this.username, this.password)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.error = error;
                    if (this.error === 'Unknown Error') {
                        this.openSnackBar('Erro desconhecido! Favor contactar suporte', 'Ok', 5000);
                    }
                    else if (this.error === 'Usuário ou senha incorretos!') {
                        this.openSnackBar('Usuário e senha incorretos!', 'Ok', 5000);
                    }
                });
    }

    openSnackBar(message: string, action: string, tempo: number) {
        this.snackBar.open(message, action, {
            duration: tempo,
        });
    }
}