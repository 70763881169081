import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBar {

    constructor(
        private snackBar: MatSnackBar,
      ) {}    
    
    openSnackBar(message: string, action: string, tempo: number) {
        this.snackBar.open(message, action, {
            duration: tempo,
        });    
    }
}