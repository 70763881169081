import { Boletim } from './../models/boletim';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoletimService {

  public boletins = [];

  private readonly endpoint = environment.apiUrl + '/api/boletins';  
  constructor(private http: HttpClient) {      
  }
 
  getBoletins(filter): Observable<Boletim[]> {
    return this.http.get<Boletim[]>(this.endpoint + '?' + this.toQueryString(filter));
  }

  toQueryString(obj) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined) 
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }


  getBoletim(id: String): Observable<Boletim> {
    return this.http.get<Boletim>(this.endpoint + '/' + id);
  }  

  updateBoletim(boletim: Boletim) {
    return this.http.put(this.endpoint + '/' + boletim.id, boletim);
    // return this.http.put(this.endpoint + '/' + boletim.id, boletim)
    //   .map((data: any[]) => {
    //     this.boletins = data;
    //     return true;
    //   })
  }

  createBoletim(boletim) {
    return this.http.post(this.endpoint, boletim);
    // return this.http.post(this.endpoint, boletim)
    //   .map((data: any[]) => {
    //     this.boletins = data;
    //     return true;
    //   })
  }

  deleteBoletim(id) {
    return this.http.delete(this.endpoint + '/' + id);
    // return this.http.delete(this.endpoint + '/' + id)
    //   .map((data: any[]) => {
    //     this.boletins = data;
    //     return true;
    //   })
  }
}