import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './../_services/authentication.service';


@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit{
  isLoggedIn: boolean;
  currentUser: string;
  currentRole: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService
  ) { 
    
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe(auth=>{
      if(auth){
        this.isLoggedIn = true;
        this.currentUser = auth.firstName;
        this.currentRole = auth.role;
      }
      else{
        this.isLoggedIn = false;
      }
    })
  }
  
  Logout(){    
    this.authenticationService.logout();
    this.isLoggedIn = false;
    this.currentUser = '';
    this.currentRole = '';
  
    this.isLoggedIn = this.authenticationService.IsLoggedIn();
    this.router.navigate(['/login']);    
  }


}
