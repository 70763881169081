import { Component, OnInit, ViewChild } from '@angular/core';
import { AlunoService } from './../../services/aluno.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-aluno-list',
  templateUrl: './aluno-list.component.html',
  styleUrls: ['./aluno-list.component.css']
})
export class AlunoListComponent implements OnInit {
  private readonly PAGE_SIZE = 5;

  temp = [];
  rows = [];

  query: any = {
    pageSize: this.PAGE_SIZE
  };


  columns = [
    { title: 'Id' },
    { title: 'Matricula', name: 'Matricula', key: 'matricula', isSortable: true },
    { title: 'Nome', name: 'Nome', key: 'nome', isSortable: true },
    { title: 'Data de Nascimento', name: 'dataNascimento', key: 'dataNascimento', isSortable: true },
    {}
  ];


  @ViewChild(DatatableComponent) table: DatatableComponent;


  constructor(
    private spinner: NgxSpinnerService,
    private alunoService: AlunoService,
    private snackBar: MatSnackBar,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {
    if (!this.authenticationService.IsLoggedIn()) {
      this.router.navigate(['/login']);
    }
    else {
      this.alunoService.getAlunos(this.query)
        .subscribe(result => {
          this.rows = result;
          this.temp = result;
        },
          error => {

            this.openSnackBar('Erro ao obter dados!', 'Ok', 5000);
          });

    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.nome.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  edit(row) {
    this.router.navigate(['/alunos/' + row.id]);
  }

  openSnackBar(message: string, action: string, tempo: number) {
    this.snackBar.open(message, action, {
      duration: tempo,
    });
  }
}
