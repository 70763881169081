import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Turma } from '../models/turma';

@Injectable()
export class TurmaService {
  public turmas = [];
  private readonly endpoint = environment.apiUrl + '/api/turmas';

  constructor(private http: HttpClient) {

  }


  getTurmas(filter): Observable<Turma[]> {
    return this.http.get<Turma[]>(this.endpoint);
  }

  getTurma(id: String): Observable<Turma> {
    return this.http.get<Turma>(this.endpoint + '/' + id);
  }

  updateTurma(turma: Turma) {
    return this.http.put(this.endpoint + '/' + turma.id, turma);
    // return this.http.put(this.endpoint + '/' + turma.id, turma)
    //   .map((data: any[]) => {
    //     this.turmas = data;
    //     return true;
    //   })
  }

  createTurma(turma) {
    return this.http.post(this.endpoint, turma);
    // return this.http.post(this.endpoint, turma)
    //   .map((data: any[]) => {
    //     this.turmas = data;
    //     return true;
    //   })
  }
  deleteTurma(id) {
    return this.http.delete(this.endpoint + '/' + id);
    // return this.http.delete(this.endpoint + '/' + id)
    //   .map((data: any[]) => {
    //     this.turmas = data;
    //     return true;
    //   })
  }
}