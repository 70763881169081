import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PeriodoService } from 'src/app/services/periodo.service';
import { TurmaService } from 'src/app/services/turma.service';
import { Periodo } from 'src/app/models/periodo';
import { Turma } from 'src/app/models/turma';
import { AlunoService } from 'src/app/services/aluno.service';
import { Aluno } from 'src/app/models/aluno';
import { NotaService } from 'src/app/services/nota.service';
import { Nota } from 'src/app/models/nota';
import { DisciplinaService } from 'src/app/services/disciplina.service';
import { Router } from '@angular/router';
// import { nodeValue } from '@angular/core/src/view';

@Component({
  selector: 'app-boletim-list',
  templateUrl: './boletim-list.component.html',
  styleUrls: ['./boletim-list.component.css']
})
export class BoletimListComponent implements OnInit {
  private readonly PAGE_SIZE = 5;

  public error: any;
  turmaInativa = true;
  alunoInativo = true;

  id: string;
  vaa: number;
  temDados: boolean = false;
  alterando: boolean = false;
  semAlunoSel: boolean = true;
  idDisciplina: number;
  idPeriodo: number;
  idTurma: number;
  idAluno: number;

  temp = [];
  rows = [];
  saveNota = [];

  periodos: Periodo[];
  disciplinas = [];
  turmas: Turma[];
  alunos: Aluno[];  



  public nota: Nota = {
    id: 0,
    boletimId: 0,
    atividadeId: 0,
    vaa: 0.0,
    fn: ''
  };


  query: any = {
    pageSize: this.PAGE_SIZE
  };

  constructor(
    private periodoService: PeriodoService,
    private disciplinaService: DisciplinaService,
    private turmaService: TurmaService,
    private alunoService: AlunoService,
    private notaService: NotaService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {

    this.periodoService.getPeriodos(this.query).subscribe(
      result => {
        this.periodos = result;
      });

    this.disciplinaService.getDisciplinas(this.query).subscribe(
      result => {
        this.disciplinas = result;
      }
    )

  }

  ngOnInit() {
  }

  filtraTurma(PeriodoId: number) {
    this.turmaInativa = (PeriodoId == 0);
    this.semAlunoSel = true;

    this.turmaService.getTurmas(this.query).subscribe(
      result => {
        this.turmas = result;
      });
  }

  filtraAlunos(TurmaId: number) {
    this.semAlunoSel = true;
    var filter: any = {};
    this.alunoInativo = (TurmaId == 0);
    filter.idPeriodo = this.idPeriodo;
    filter.idTurma = this.idTurma;

    this.alunoService.getAlunosTurma(filter).subscribe(
      result => {
        this.alunos = result;
      }
    )
  }

  alunoSelecionado(AlunoId: number) {
    this.semAlunoSel = AlunoId == 0;
  }

  buscarNotas() {
    this.query.idAluno = this.idAluno;
    this.query.idDisciplina = this.idDisciplina;
    this.query.idPeriodo = this.idPeriodo;
    this.query.idTurma = this.idTurma;
    this.alterando = false;

    this.notaService.getNotas(this.query).subscribe(
      result => {
        this.temp = result;
        for (var item of this.temp) {
          if (item.atividade.vma > 0) {
            item.paa = item.vaa / item.atividade.vma * 100;
            //item.paa = formatNumber(item.vaa / item.atividade.vma * 100,'pt-BR', '2,2');
          }
          else {
            item.paa = 0;
          }
          item.paa = parseFloat(item.paa).toFixed(1);
          //item.paa = formatNumber(item.vaa / item.atividade.vma * 100,'pt-BR', '2,2');

          if (item.atividade.tipoAtividade.tipoIndice == "C") {
            item.atividade.tipoAtividade.tipoIndice = "Cognitivo"
          }
          else {
            item.atividade.tipoAtividade.tipoIndice = "Formativo"
          }
        }
        this.rows = this.temp;        
        this.temDados = this.rows.length > 0;

        for (var i = 0, len = this.temp.length; i < len; i++) {
          this.saveNota[i] = {}
          for (var prop in this.temp[i]) {
            this.saveNota[i][prop] = this.temp[i][prop];
          }
        }
      });
  }

  updateValue(event, cell, rowIndex) {
    var valAnt = this.rows[rowIndex][cell];
    var newVal = event.target.value;

    var vma = this.rows[rowIndex].atividade.vma;
    if (newVal == '') {
      newVal = 0;
      this.alterando = true;      
    }

    if (isNaN(newVal)){
      this.openSnackBar('Favor digitar um número válido!', 'Ok', 2000);
      event.target.value = valAnt;
      return;
    }

    if (newVal > vma) {
      this.openSnackBar('VAA não pode ser maior do que VMA!', 'Ok', 2000);
      event.target.value = valAnt;
    } else {
      if (newVal != valAnt) {
        this.alterando = true;
        this.rows[rowIndex][cell] = newVal;
        this.rows[rowIndex]['fn'] = 'F';
        this.rows[rowIndex].paa = Number(newVal / vma * 100).toFixed(1);
        this.rows = [...this.rows];
      }
    }
  }


  setPA(rowIndex, value) {
    if (value == 'T'){
      this.rows[rowIndex].fn = 'F';      
      
    }
    else{      
      this.rows[rowIndex].fn = 'T';
      this.rows[rowIndex]['vaa'] = 0;
      this.rows[rowIndex]['paa'] = 0;
    }
    this.alterando = true;
  }

  openSnackBar(message: string, action: string, tempo: number) {
    this.snackBar.open(message, action, {
      duration: tempo,
    });
  }

  abrirBoletim() {
    this.router.navigate(['/boletins/' + this.rows[0].boletimId]);
  }

  salvar() {

    var exibiuMsg = false;
    for (let i = 0; i < this.saveNota.length; i++) {
      // if (this.nota.fn != this.rows[i].fn || this.saveNota[i].vaa != this.rows[i].vaa) {
        if (this.saveNota[i].fn != this.rows[i].fn || this.saveNota[i].vaa != this.rows[i].vaa) {        
        this.nota.id = this.rows[i].id;
        this.nota.boletimId = this.rows[i].boletimId;
        this.nota.atividadeId = this.rows[i].atividadeId;
        this.nota.vaa = this.rows[i].vaa;
        this.nota.fn = this.rows[i].fn;
        this.notaService.updateNota(this.nota)
          .subscribe(x => {
            this.saveNota[i].vaa = this.rows[i].vaa;
            if (!exibiuMsg) {
              this.openSnackBar('Dados gravados com sucesso!', 'Ok', 2000);
              exibiuMsg = true;
            }
            this.alterando = false;
          },
            error => {
              this.error = true;
              console.log(error.message);
              this.openSnackBar('Atenção: Erro ao gravar dados! Favor tentar novamente.', 'Ok', 5000);
            });

      }
    }
  }

  cancelar() {
    this.buscarNotas();
  }
};

