import { Component, OnInit } from '@angular/core';
import { Periodo } from 'src/app/models/periodo';
import { Aluno } from 'src/app/models/aluno';
import { PeriodoService } from 'src/app/services/periodo.service';
import { AlunoService } from 'src/app/services/aluno.service';
import { TurmaService } from 'src/app/services/turma.service';
import { Turma } from 'src/app/models/turma';
import { Boletim } from 'src/app/models/boletim';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrintPdfService } from 'src/app/services/print-pdf.service';

@Component({
  selector: 'app-boletim-form',
  templateUrl: './boletim-form.component.html',
  styleUrls: ['./boletim-form.component.css']
})
export class BoletimFormComponent implements OnInit {
  private readonly PAGE_SIZE = 5;

  idPeriodo: number;
  idTurma: number;
  idAluno: number;
  turmaInativa = true;
  alunoInativo = true;
  idTipoImpressao = 0;

  query: any = {
    pageSize: this.PAGE_SIZE
  };

  boletins: Boletim[];
  periodos: Periodo[];
  alunos: Aluno[];
  turmas: Turma[];

  constructor(
    private periodoService: PeriodoService,
    private alunoService: AlunoService,
    private turmaService: TurmaService,    
    private snackBar: MatSnackBar,
    private printPdfService: PrintPdfService
  ) {

    this.periodoService.getPeriodos(this.query).subscribe(
      result => {
        this.periodos = result;
        //this.periodos.splice(0,0,{id:0, nome: "Selecione..."});
      });
  }

  ngOnInit() {
  }
  
  filtraTurma(PeriodoId: number){
    this.turmaInativa = (PeriodoId == 0);

    this.turmaService.getTurmas(this.query).subscribe(
      result => {
        this.turmas = result;
      });
  }

  filtraAlunos(TurmaId: number){
    var filter: any = {};
    this.alunoInativo = (TurmaId == 0);
    filter.idPeriodo = this.idPeriodo;
    filter.idTurma = this.idTurma;

    this.alunoService.getAlunosTurma(filter).subscribe(
      result => {
        this.alunos = result;
      }
    )
  }
  
  imprimirBoletins(idTipoImpressao: number) {
    var filter: any = {};
    if (this.idAluno > 0  && this.idTurma > 0 && this.idPeriodo > 0) {
      filter.idPeriodo = this.idPeriodo;
      filter.idTurma = this.idTurma;
      filter.idAluno = this.idAluno;      
      this.printPdfService.printLoad(filter, idTipoImpressao);

    }
    else {
      this.openSnackBar('Selecione o aluno!', 'Ok', 2000);      
    }
  }
  
  openSnackBar(message: string, action: string, tempo: number) {
    this.snackBar.open(message, action, {
      duration: tempo,
    });
  }
 
}
